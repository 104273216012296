export const amazonRegions = [
  {
    group: "North America",
    region: "ca-central-1",
    url: "https://sellercentral.amazon.ca",
    value: "Canada"
  },
  {
    group: "North America",
    region: "us-east-1",
    url: "https://sellercentral.amazon.com",
    value: "US"
  },
  {
    group: "North America",
    region: "us-east-1",
    url: "https://sellercentral.amazon.com.mx",
    value: "Mexico"
  },
  {
    group: "South America",
    region: "sa-east-1",
    url: "https://sellercentral.amazon.com.br",
    value: "Brazil"
  },
  {
    group: "Europe",
    region: "eu-west-1",
    url: "https://sellercentral-europe.amazon.com",
    value: "Spain"
  },
  {
    group: "Europe",
    region: "eu-west-2",
    url: "https://sellercentral-europe.amazon.com",
    value: "UK"
  },
  {
    group: "Europe",
    region: "eu-west-3",
    url: "https://sellercentral-europe.amazon.com",
    value: "France"
  },
  {
    group: "Europe",
    region: "eu-central-1",
    url: "https://sellercentral.amazon.nl",
    value: "Netherlands"
  },
  {
    group: "Europe",
    region: "eu-central-1",
    url: "https://sellercentral-europe.amazon.com",
    value: "Germany",
    default: true
  },
  {
    group: "Europe",
    region: "eu-south-1",
    url: "https://sellercentral-europe.amazon.com",
    value: "Italy"
  },
  {
    group: "Europe",
    region: "eu-north-1",
    url: "https://sellercentral.amazon.se",
    value: "Sweden"
  },
  {
    group: "Africa",
    region: "af-south-1",
    url: "https://sellercentral.amazon.co.za",
    value: "South Africa"
  },
  {
    group: "Europe",
    region: "eu-central-1",
    url: "https://sellercentral.amazon.pl",
    value: "Poland"
  },
  {
    group: "Africa",
    region: "me-south-1",
    url: "https://sellercentral.amazon.eg",
    value: "Egypt"
  },
  {
    group: "Asia",
    region: "eu-central-1",
    url: "https://sellercentral.amazon.com.tr",
    value: "Turkey"
  },
  {
    group: "Asia",
    region: "me-south-1",
    url: "https://sellercentral.amazon.sa",
    value: "Saudi Arabia"
  },
  {
    group: "Asia",
    region: "me-central-1",
    url: "https://sellercentral.amazon.ae",
    value: "U.A.E"
  },
  {
    group: "Asia",
    region: "ap-south-1",
    url: "https://sellercentral.amazon.in",
    value: "India"
  },
  {
    group: "Asia",
    region: "ap-southeast-1",
    url: "https://sellercentral.amazon.sg",
    value: "Singapore"
  },
  {
    group: "Asia",
    region: "ap-southeast-2",
    url: "https://sellercentral.amazon.com.au",
    value: "Australia"
  },
  {
    group: "Asia",
    region: "ap-northeast-1",
    url: "https://sellercentral.amazon.co.jp",
    value: "Japan"
  }
];

export const amazonDefaultRegion = amazonRegions?.find(
  region => region.default
);
