import ApiService from "@/core/services/api.service";
import { BaseService } from "@/components/Tools/baseService";

const url = process.env.VUE_APP_API_ADMIN;
const basePath = "/apps/checkExternalConnection/";
const baseUrl = url + basePath;

class ExternalConnection extends BaseService {
  checkApi(params = {}) {
    return ApiService.post(baseUrl + "api", params);
  }

  checkSftp(params = {}) {
    return ApiService.post(baseUrl + "sftp", params);
  }
}
export default new ExternalConnection();
