import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "@/core/services/store";
import { oAuthConfig } from "@/components/ExternalApps/SalesChannelManagementApp/utility/oAuthConfig";
import {
  amazonDefaultRegion,
  amazonRegions
} from "@/components/ExternalApps/SalesChannelManagementApp/utility/amazonRegions";
import i18n from "@/core/plugins/vue-i18n";
import { updateOauthEnvironment } from "@/composables/useSalesChannelManagementApp";
import { labelData } from "@/components/ExternalApps/SalesChannelManagementApp/utility/labelData";

export default function useOauth(integration) {
  const store = useStore();
  const value = ref({});
  const authSuccess = ref(false);
  const wasAlreadyInstalled = !!store.getters.integrations.length;

  const formConfig = {
    enableVariables: false,
    labelStacked: true,
    snippetPrefix: "salesChannelManagementApp",
    ...labelData.value
  };

  const selectedAppName = computed(() => store.getters.selectedApp.name);
  const selectedAppLabel = computed(() => store.getters.selectedApp.label);
  const canToggleSandBox = computed(
    () =>
      store.getters.selectedApp?.environmentVariables?.oauth
        ?.canToggleSandbox ?? false
  );

  const fromUrl =
    store.getters.xentralConfig?.data?.baseUrl?.replace(/\/$/, "") || "";

  const stateObj = computed(() => {
    return {
      licenseId: store.getters.licenseId,
      selectedAppName: selectedAppName.value,
      from_url:
        fromUrl + "/app/app/connect-" + selectedAppName.value + "?state=",
      integration: {
        ...integration,
        credentials: { ...value.value, ...handleAppSpecificConfiguration() }
      }
    };
  });

  const module = computed(() => {
    let tempModule = oAuthConfig?.find(
      system => system.name === selectedAppName.value
    );
    tempModule.config.form.disabled = authSuccess.value;

    return tempModule;
  });

  const form = computed(() => module.value?.config?.form ?? []);

  const label = i18n.t(
    `salesChannelManagementApp.${selectedAppName.value}.useSandbox`,
    { appName: selectedAppLabel.value }
  );
  // if  third party app allows to switch between sandbox & production => inject switch
  if (canToggleSandBox.value && !form.value.length) {
    form.value.push({
      name: "toggleSandbox",
      infoText: label,
      type: "checkbox",
      default: false
    });
  }

  // Display permanent installation link as firs step
  const ottoInvitationLinkButton = computed(() =>
    selectedAppName.value === "otto" && !value.value?.toggleSandbox
      ? process.env.VUE_APP_OTTO_APP_INSTALLATION_URL
      : ""
  );

  const params = computed(() => {
    const computedParams = {};
    for (const param of module.value.config.params) {
      if (param === "client_id" && !value.value?.toggleSandbox) {
        computedParams[param] = store.getters.oAuthClientId;
        continue;
      }
      computedParams[param] = store.getters.oauthVariable(param);
    }
    return computedParams;
  });

  /**
   * @param state
   * @param baseUrl
   * @param params (key : value)
   * @returns {string}
   */
  function generateAuthUrl(state, baseUrl, params) {
    params["state"] = btoa(JSON.stringify(stateObj.value));
    params = new URLSearchParams(params);
    return `${baseUrl}?${params}`;
  }

  function handleAppSpecificConfiguration() {
    if (selectedAppName.value === "amazon") {
      return {
        region: amazonRegions.find(
          region => region.value === value.value?.region
        )?.region
      };
    }
  }

  const isStaging = computed(() => value.value?.toggleSandbox ?? false);

  const baseUrls = computed(() => ({
    amazon:
      (amazonRegions?.find(region => region.value === value.value?.region)
        ?.url ?? amazonDefaultRegion?.url) + "/apps/authorize/consent",
    shopify: "https://admin.shopify.com/admin/oauth/authorize",
    otto:
      "https://" +
      (isStaging.value ? "sandbox." : "") +
      "api.otto.market/oauth2/auth"
  }));

  const baseUrl = computed(() => baseUrls.value?.[selectedAppName.value]);

  const authorizationUrl = computed(() => {
    return generateAuthUrl(stateObj, baseUrl.value, params.value);
  });

  watch(
    () => value.value?.toggleSandbox,
    () => {
      // Determine the correct environment suffix
      const envSuffix = value.value?.toggleSandbox ? "_SANDBOX" : "";
      const upperAppName = selectedAppName.value.toUpperCase();

      // Construct client_id and redirect_uri
      const clientId =
        process.env[`VUE_APP_${upperAppName}_APP_CLIENT_ID${envSuffix}`];
      const redirectUri =
        process.env[
          `VUE_APP_${upperAppName}_APP_APPLICATION_REDIRECT_URL${envSuffix}`
        ];

      // Update OAuth environment
      updateOauthEnvironment({
        appName: selectedAppName.value,
        newClientId: clientId,
        redirectUri: redirectUri
      });
    }
  );

  onMounted(() => {
    if (window.location?.href?.split("config=").length) {
      authSuccess.value = integration?.credentials?.success;
    }

    const usesDraftApp = process.env.VUE_APP_ENVIROMENT === "staging";
    if (selectedAppName.value === "amazon" && !usesDraftApp) {
      // This indicates that the authorization workflow is for an application in draft status.
      const versionParamIndex = module.value?.config?.params.indexOf("version");
      module.value.config.params.splice(versionParamIndex, 1);
    }
  });

  return {
    authorizationUrl,
    ottoInvitationLinkButton,
    wasAlreadyInstalled,
    value,
    authSuccess,
    formConfig,
    form,
    stateObj,
    params,
    selectedAppName
  };
}
