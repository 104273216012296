import {
  amazonDefaultRegion,
  amazonRegions
} from "@/components/ExternalApps/SalesChannelManagementApp/utility/amazonRegions";

export const oAuthConfig = [
  {
    name: "amazon",
    config: {
      form: [
        {
          type: "select",
          label: "amazon.region",
          name: "region",
          clearable: true,
          default: amazonDefaultRegion.value,
          options: amazonRegions,
          validations: {
            required: true
          }
        }
      ],
      params: ["application_id", "redirect_uri", "version"]
    }
  },
  {
    name: "shopify",
    config: {
      form: [],
      params: ["no_redirect", "client_id", "redirect_uri"]
    }
  },
  {
    name: "otto",
    config: {
      form: [],
      params: ["client_id", "response_type", "scope"]
    }
  }
];
